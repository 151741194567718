import { IDENTITY_TYPES_MAP } from '@verifime/utils';
import { TAssessmentStatus, TCountry, TOption, TRiskLevel, TState } from './types';

export const APPLICATION_NAME = 'VerifiMe Client Portal';

export enum CountryCode {
  AUS = 'AUS',
  NZL = 'NZL',
  BGR = 'BGR',
  BFA = 'BFA',
  CMR = 'CMR',
  COG = 'COG',
  RDC = 'RDC',
  HRV = 'HRV',
  HTI = 'HTI',
  IRN = 'IRN',
  KEN = 'KEN',
  KYA = 'KYA',
  PRK = 'PRK',
  MLI = 'MLI',
  MCO = 'MCO',
  MOZ = 'MOZ',
  MMR = 'MMR',
  NAM = 'NAM',
  NGA = 'NGA',
  PHL = 'PHL',
  SEN = 'SEN',
  ZAF = 'ZAF',
  SSD = 'SSD',
  SYR = 'SYR',
  TZA = 'TZA',
  VEN = 'VEN',
  VNM = 'VNM',
  YEM = 'YEM',
}

export const AUS: TCountry = { code: CountryCode.AUS, label: 'Australia', phone: '61' };
export const NZL: TCountry = { code: CountryCode.NZL, label: 'New Zealand', phone: '64' };
export const BGR: TCountry = { code: CountryCode.BGR, label: 'Bulgaria', phone: '359' };
export const BFA: TCountry = { code: CountryCode.BFA, label: 'Burkina Faso', phone: '226' };
export const CMR: TCountry = { code: CountryCode.CMR, label: 'Cameroon', phone: '237' };
export const COG: TCountry = { code: CountryCode.COG, label: 'Congo', phone: '242' };
export const RDC: TCountry = {
  code: CountryCode.RDC,
  label: 'Democratic Republic of the Congo',
  phone: '243',
};
export const HRV: TCountry = { code: CountryCode.HRV, label: 'Croatia', phone: '385' };
export const HTI: TCountry = { code: CountryCode.HTI, label: 'Haiti', phone: '509' };
export const IRN: TCountry = { code: CountryCode.IRN, label: 'Iran', phone: '98' };
export const KEN: TCountry = { code: CountryCode.KEN, label: 'Kenya', phone: '254' };
export const KYA: TCountry = { code: CountryCode.KYA, label: 'Kyrgyzstan', phone: '996' };
export const PRK: TCountry = { code: CountryCode.PRK, label: 'North Korea', phone: '850' };
export const MLI: TCountry = { code: CountryCode.MLI, label: 'Mali', phone: '223' };
export const MCO: TCountry = { code: CountryCode.MCO, label: 'Monaco', phone: '377' };
export const MOZ: TCountry = { code: CountryCode.MOZ, label: 'Mozambique', phone: '258' };
export const MMR: TCountry = { code: CountryCode.MMR, label: 'Myanmar', phone: '95' };
export const NAM: TCountry = { code: CountryCode.NAM, label: 'Namibia', phone: '264' };
export const NGA: TCountry = { code: CountryCode.NGA, label: 'Nigeria', phone: '234' };
export const PHL: TCountry = { code: CountryCode.PHL, label: 'Philippines', phone: '63' };
export const SEN: TCountry = { code: CountryCode.SEN, label: 'Senegal', phone: '221' };
export const ZAF: TCountry = { code: CountryCode.ZAF, label: 'South Africa', phone: '27' };
export const SSD: TCountry = { code: CountryCode.SSD, label: 'South Sudan', phone: '211' };
export const SYR: TCountry = { code: CountryCode.SYR, label: 'Syrian Arab Republic', phone: '963' };
export const TZA: TCountry = { code: CountryCode.TZA, label: 'Tanzania', phone: '255' };
export const VEN: TCountry = { code: CountryCode.VEN, label: 'Venezuela', phone: '58' };
export const VNM: TCountry = { code: CountryCode.VNM, label: 'Vietnam', phone: '84' };
export const YEM: TCountry = { code: CountryCode.YEM, label: 'Yemen', phone: '967' };

export const COUNTRY_MAP: { [key: string]: TCountry } = {
  AUS,
  NZL,
  BGR,
  BFA,
  CMR,
  COG,
  RDC,
  HRV,
  HTI,
  IRN,
  KEN,
  KYA,
  PRK,
  MLI,
  MCO,
  MOZ,
  MMR,
  NAM,
  NGA,
  PHL,
  SEN,
  ZAF,
  SSD,
  SYR,
  TZA,
  VEN,
  VNM,
  YEM,
};

export const COUNTRIES: TCountry[] = [
  AUS,
  NZL,
  BGR,
  BFA,
  CMR,
  COG,
  RDC,
  HRV,
  HTI,
  IRN,
  KEN,
  KYA,
  PRK,
  MLI,
  MCO,
  MOZ,
  MMR,
  NAM,
  NGA,
  PHL,
  SEN,
  ZAF,
  SSD,
  SYR,
  TZA,
  VEN,
  VNM,
  YEM,
];

export const STATES: TState[] = [
  { code: 'ACT', label: 'ACT', countryCode: 'AUS' },
  { code: 'NSW', label: 'NSW', countryCode: 'AUS' },
  { code: 'NT', label: 'NT', countryCode: 'AUS' },
  { code: 'QLD', label: 'QLD', countryCode: 'AUS' },
  { code: 'SA', label: 'SA', countryCode: 'AUS' },
  { code: 'TAS', label: 'TAS', countryCode: 'AUS' },
  { code: 'VIC', label: 'VIC', countryCode: 'AUS' },
  { code: 'WA', label: 'ACT', countryCode: 'AUS' },
];

export const GENDERS: TOption[] = [
  { code: 'female', label: 'Female' },
  {
    code: 'male',
    label: 'Male',
  },
  { code: 'other', label: 'Other' },
  { code: 'notToSay', label: 'Prefer not to say' },
];

export enum EntityStatus {
  Empty = 'Empty',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export enum ErrorType {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export enum ErrorScope {
  Field = 'Field',
  Form = 'Form',
  Global = 'Global',
}

export enum FormStatus {
  UNKNOWN,
  UNFILLED,
  FILLED,
  DOING_UPDATE,
}

export enum DocumentType {
  WholesaleCertificate = 'WholesaleCertificate',
  Passport = 'Passport',
  DriverLicence = 'DriverLicence',
}

export enum RiskLevel {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Extreme = 'Extreme',
}

export enum AssessmentStatus {
  Open = 'Open',
  Closed = 'Closed',
  InProgress = 'InProgress',
  NoRisksDetected = 'NoRisksDetected',
}

export const AssessmentStatusOpen: TAssessmentStatus = {
  code: AssessmentStatus.Open,
  label: 'Open',
};

export const AssessmentStatusClosed: TAssessmentStatus = {
  code: AssessmentStatus.Closed,
  label: 'Closed',
};

export const AssessmentStatusNoRisksDetected: TAssessmentStatus = {
  code: AssessmentStatus.NoRisksDetected,
  label: 'No Risks Detected',
};

export const AssessmentStatusInProgress: TAssessmentStatus = {
  code: AssessmentStatus.InProgress,
  label: 'In Progress',
};

export const ASSESSMENT_STATUS: TAssessmentStatus[] = [
  AssessmentStatusOpen,
  AssessmentStatusClosed,
  AssessmentStatusNoRisksDetected,
];

export const RiskLevelLow: TRiskLevel = {
  code: RiskLevel.Low,
  label: 'Low',
};

export const RiskLevelMedium: TRiskLevel = {
  code: RiskLevel.Medium,
  label: 'Medium',
};

export const RiskLevelHigh: TRiskLevel = {
  code: RiskLevel.High,
  label: 'High',
};

export const RiskLevelExtreme: TRiskLevel = {
  code: RiskLevel.Extreme,
  label: 'Extreme',
};

export const RISK_SCORE: Record<string, number> = {
  Low: 1,
  Medium: 2,
  High: 3,
  Extreme: 4,
};

export const RISK_LEVELS: TRiskLevel[] = [
  RiskLevelLow,
  RiskLevelMedium,
  RiskLevelHigh,
  RiskLevelExtreme,
];
export enum MitigationStatus {
  Open = 'Open',
  Pass = 'Pass',
  Ignore = 'Ignore',
  Fail = 'Fail',
}

export const ASSESSMENT_STATUS_MAP = {
  [AssessmentStatus.Open]: AssessmentStatusOpen,
  [AssessmentStatus.Closed]: AssessmentStatusClosed,
  [AssessmentStatus.NoRisksDetected]: AssessmentStatusNoRisksDetected,
  [AssessmentStatus.InProgress]: AssessmentStatusInProgress,
};

export enum Comparison {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  Contains = 'Contains',
  NotContains = 'NotContains',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
}

const EqualsComparisonOption: TOption = { code: 'Equals', label: 'Equals' };
const NotEqualsComparisonOption: TOption = { code: 'NotEquals', label: 'NotEquals' };
const ContainsComparisonOption: TOption = { code: 'Contains', label: 'Contains' };
const NotContainsComparisonOption: TOption = { code: 'NotContains', label: 'NotContains' };
const GreaterThanComparisonOption: TOption = { code: 'GreaterThan', label: 'GreaterThan' };
const LessThanComparisonOption: TOption = { code: 'LessThan', label: 'LessThan' };
const GreaterThanOrEqualToComparisonOption: TOption = {
  code: 'GreaterThanOrEqualTo',
  label: 'GreaterThanOrEqualTo',
};
const LessThanOrEqualToComparisonOption: TOption = {
  code: 'LessThanOrEqualTo',
  label: 'LessThanOrEqualTo',
};

// The comparison label is the same as the code for now. May change the label to be more readable later
export const COMPARISON_OPTIONS: TOption[] = [
  EqualsComparisonOption,
  NotEqualsComparisonOption,
  ContainsComparisonOption,
  NotContainsComparisonOption,
  GreaterThanComparisonOption,
  LessThanComparisonOption,
  GreaterThanOrEqualToComparisonOption,
  LessThanOrEqualToComparisonOption,
];

export enum RuleAttribute {
  entityType = 'entityType',
  entityStatus = 'entityStatus',
  countryCode = 'countryCode',
  wholesaleCertificateVerified = 'wholesaleCertificateVerified',
  identityDocumentVerifiedCount = 'identityDocumentVerifiedCount',
  pepDetected = 'pepDetected',
  sanctionsDetected = 'sanctionsDetected',
  livenessCheckCompleted = 'livenessCheckCompleted',
  livenessVideoAvailable = 'livenessVideoAvailable',
  passportIssuer = 'passportIssuer',
  driverLicenceIssuer = 'driverLicenceIssuer',
  otherIdentityDocumentIssuer = 'otherIdentityDocumentIssuer',
  portraitSimilarity = 'portraitSimilarity',
}

export const RULE_ATTRIBUTES: string[] = [
  RuleAttribute.entityType,
  RuleAttribute.entityStatus,
  RuleAttribute.countryCode,
  RuleAttribute.wholesaleCertificateVerified,
  RuleAttribute.identityDocumentVerifiedCount,
  RuleAttribute.pepDetected,
  RuleAttribute.sanctionsDetected,
  RuleAttribute.livenessCheckCompleted,
  RuleAttribute.livenessVideoAvailable,
  RuleAttribute.passportIssuer,
  RuleAttribute.driverLicenceIssuer,
  RuleAttribute.otherIdentityDocumentIssuer,
  RuleAttribute.portraitSimilarity,
];

type TRuleAttributeMeta = {
  comparisonList?: TOption[];
  valueList: TOption[] | string[];
};

const booleanRuleAttributeMeta: TRuleAttributeMeta = {
  comparisonList: [EqualsComparisonOption, NotEqualsComparisonOption],
  valueList: ['true', 'false'],
};

function equalityRuleCriteriaAttribute(valueList: TOption[] | string[]) {
  return {
    comparisonList: [
      EqualsComparisonOption,
      NotEqualsComparisonOption,
      ContainsComparisonOption,
      NotContainsComparisonOption,
    ],
    valueList: valueList,
  };
}

function comparisonRuleCriteriaAttribute(valueList: string[]) {
  return {
    comparisonList: [
      GreaterThanComparisonOption,
      GreaterThanOrEqualToComparisonOption,
      LessThanComparisonOption,
      LessThanOrEqualToComparisonOption,
    ],
    valueList: valueList,
  };
}

/**
 * FIXME: We need to remove these identity types from DB and code.
 */
export enum UnsupportedIdentityTypeCode {
  Charity_Association = 'Charity_Association',
  Individual_Foreign = 'Individual_Foreign',
  Private_Foreign_Company = 'Private_Foreign_Company',
  Public_Foreign_Company = 'Public_Foreign_Company',
}

const UNSUPPORTED_IDENTITY_TYPES_MAP: {
  [key in UnsupportedIdentityTypeCode]: TOption;
} = {
  [UnsupportedIdentityTypeCode.Charity_Association]: {
    code: UnsupportedIdentityTypeCode.Charity_Association,
    label: 'Charity Association',
  },
  [UnsupportedIdentityTypeCode.Individual_Foreign]: {
    code: UnsupportedIdentityTypeCode.Individual_Foreign,
    label: 'Individual (Foreign)',
  },
  [UnsupportedIdentityTypeCode.Private_Foreign_Company]: {
    code: UnsupportedIdentityTypeCode.Private_Foreign_Company,
    label: 'Private Company (Foreign)',
  },
  [UnsupportedIdentityTypeCode.Public_Foreign_Company]: {
    code: UnsupportedIdentityTypeCode.Public_Foreign_Company,
    label: 'Public Company (Foreign)',
  },
};

// FIXME: Why do we have both Charity and Charity_Association? We should just have one!
const { Charity, ...IDENTITY_TYPES_MAP_WITHOUT_CHARITY } = IDENTITY_TYPES_MAP;

export const ALL_IDENTITY_TYPES_MAP = {
  ...IDENTITY_TYPES_MAP_WITHOUT_CHARITY,
  ...UNSUPPORTED_IDENTITY_TYPES_MAP,
};

export const RULE_ATTRIBUTE_MAP: {
  [key in RuleAttribute]: TRuleAttributeMeta;
} = {
  [RuleAttribute.entityType]: equalityRuleCriteriaAttribute(Object.values(ALL_IDENTITY_TYPES_MAP)),
  [RuleAttribute.entityStatus]: equalityRuleCriteriaAttribute(Object.keys(EntityStatus)),
  [RuleAttribute.countryCode]: equalityRuleCriteriaAttribute(Object.keys(COUNTRY_MAP)),
  [RuleAttribute.wholesaleCertificateVerified]: booleanRuleAttributeMeta,
  [RuleAttribute.identityDocumentVerifiedCount]: comparisonRuleCriteriaAttribute([
    '1',
    '2',
    '3',
    '4',
    '5',
  ]),
  [RuleAttribute.pepDetected]: booleanRuleAttributeMeta,
  [RuleAttribute.sanctionsDetected]: booleanRuleAttributeMeta,
  [RuleAttribute.livenessCheckCompleted]: booleanRuleAttributeMeta,
  [RuleAttribute.livenessVideoAvailable]: booleanRuleAttributeMeta,
  [RuleAttribute.passportIssuer]: equalityRuleCriteriaAttribute(Object.keys(COUNTRY_MAP)),
  [RuleAttribute.driverLicenceIssuer]: equalityRuleCriteriaAttribute(Object.keys(COUNTRY_MAP)),
  [RuleAttribute.otherIdentityDocumentIssuer]: equalityRuleCriteriaAttribute(Object.keys(COUNTRY_MAP)),
  [RuleAttribute.portraitSimilarity]: comparisonRuleCriteriaAttribute(
    process.env.NEXT_PUBLIC_RISK_ASSESSMENT_RULE_CRITERIA_PORTRAIT_SIMILARITY_VALUELIST?.split(','),
  ),
};
